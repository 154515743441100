@import "fonts";
@import "bootstrap";
@import "helpers";
@import "~react-notifications-component/dist/theme.css";

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  background-color: #eef1f6;
}

h1,
h2 {
  font-size: 20px;
  color: #004e93;
  margin-bottom: 15px;
  font-weight: bold;
}

.input-search {
  background: transparent;
  color: #2d2c2c;  
  outline: none;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #d3d3d3;  
  font-size: 12px;
  width: 100%;
  height: 38px;
  overflow: hidden; 
  &::-webkit-input-placeholder {
    color: #2d2c2c;
  }
  &:-ms-input-placeholder {
    color: #2d2c2c;
  }
  &::-ms-input-placeholder {
    color: #2d2c2c;
  }
  &::placeholder {
    color: #2d2c2c;
  }

  &:visited,
  &:focus,
  &:hover,
  &:active {
    outline: none;
    box-shadow: none;
    text-decoration: none;
    background: transparent;
    border-bottom: 1px solid #d3d3d3;  
  }

}

.menu-search {
  background: transparent;
  color: #eeeeee;
  border: 1px solid #eeeeee;
  outline: none;
  padding: 0 10px;
  font-size: 12px;
  width: 100%;
  height: 38px;
  overflow: hidden;
  border-radius: 20px;
  font-style: italic;

  &::-webkit-input-placeholder {
    color: #eeeeee;
  }
  &:-ms-input-placeholder {
    color: #eeeeee;
  }
  &::-ms-input-placeholder {
    color: #eeeeee;
  }
  &::placeholder {
    color: #eeeeee;
  }
  &:visited, 
  &:active,
  &:checked,
  &:focus
 {
    border: 1px solid #004c94;
    box-shadow: inset 2px 2px 5px #2d2c2cd6;
    outline: none;
    }
}

.menu-search-selected {
  color: #004c94;;
  background-color: #fff;
  border: 1px solid #004c94;
  width: 110%;
  outline: none;
  padding: 0px 15px;
  font-size: 12px;
  height: 38px;
  overflow: hidden;
  border-radius: 20px;
  font-style: italic;
  box-shadow: inset 2px 2px 5px #2d2c2cd6;
  cursor: text;
  &::-webkit-input-placeholder {
    color: #004c94;
  }
  &:-ms-input-placeholder {
    color: #004c94;
  }
  &::-ms-input-placeholder {
    color: #004c94;
  }
  &::placeholder {
    color: #004c94;
  }
  &:visited, 
  &:active,
  &:checked,
  &:focus,
  &:hover{
    border: 1px solid #004c94;
    box-shadow: inset 2px 2px 5px #2d2c2cd6;
    outline: none;
    }
  }


.form-menu-selected {
  button {
    position: absolute;
    top: 15%;
    right: 0;
    background: #0b5196;
    border: none;
    border-radius: 50%;
    outline: none;
  }

  i {
    cursor: pointer;
    color: #fff;
  }
}

.input-text,
.input-select {
  background: transparent;
  font-weight: bold;
  color: #2d2c2c;
  border: 0;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 30px;
  outline: none;
  padding: 0 10px;
  font-size: 12px;
  width: 100%;
  height: 38px;
  overflow: hidden;  
  -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none;
   
  option{
    background: #e5e5e5;
    color: #004c94;
    font-weight: bold;

  &:visited, 
  &:active,
  &:checked,
  &:focus,
  &:hover{
      background: #004c94;
      color: white;
    }
  }
}

.form-select{
  overflow: hidden;
  i{
    position: absolute;   
    right: 7%;
    top: 6%;
    font-size: 22px;
    color: #004e93;
    pointer-events: none;
  }
}

.form-search {
  position: relative;
 
  i {
    position: absolute;
    color: #004e93;
    cursor: pointer;

    &.fa-search {
      top: 12px;
      right: 15px;
    }

    &.fa-times {
      top: 12px;
      right: 10px;
    }
  }
}

.form-menu {
  position: relative;

  .input-search {
    padding-left: 15px;
  }

  i {
    position: absolute;
    color: #eeeeee;
    cursor: pointer;

    &.fa-search {
      top: 12px;
      right: 15px;
    }

    &.fa-times {
      top: 12px;
      right: 10px;
    }
  }
}

.btn {
  display: block;
  width: 100%;
  height: 43px;
  line-height: 30px;
  font-size: 13px;
  text-align: center;
  border-radius: 5px;
  transition: all 300ms linear;
  background: none;
  border: 1px solid transparent;
  color: #7d7d7d;
  font-weight: bold;
  white-space: nowrap;
  outline: none;

  
  &:visited,
  &:focus,
  &:hover,
  &:active {
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }

  &.btn-white-yellow {
    border-color: #7d7d7d;
    color: #7d7d7d;
    background-color: #ffffff;

    &:hover {
      color: #004c94;
      border-color: #ffef00;
      background-color: #ffef00;
      text-decoration: none;
    }
  }

  &.btn-default {
    border-color: #3d3d3d;
    color: #004c94;
    background-color: #ffffff;

    &.active,
    &:hover {
      color: #ffffff;
      border-color: #004c94;
      background-color: #004c94;
      text-decoration: none;
    }
    &.active:hover {
      color: #004c94;
      border-color: #ffef00;
      background-color: #ffef00;
    }
  }

  &.btn-primary {
    color: #ffffff;
    border-color: #004c94;
    background-color: #004c94;

    &:hover {
      color: #004c94;
      border-color: #ffef00;
      background-color: #ffef00;
      text-decoration: none;
    }
    &:disabled,
    &.disabled {
      cursor: not-allowed;
      color: #dedede;
      border-color: #bbbbbb;
      background-color: #bbbbbb;
    }
  }

  &.btn-secondary {
    border-color: #898989;
    color: #363636;
    background-color: #fff;

    i {
      color: #004c94;
      margin-right: 5px;
    }

    &:hover {
      &,
      i {
        color: #ffffff;
      } 
      color: #363636;     
      border-color: #004c94;
      background-color: #004c94;
    }
  }

  &.btn-transparent {
    border-color: #898989;
    color: #363636;
    background-color: transparent;

    i {
      color: #004c94;
      margin-right: 5px;
    }
    &:hover {
      &,
      i {
        color: #ffffff;
      } 
      color: #363636;     
      border-color: #004c94;
      background-color: #004c94;
    }
   
  }

  
}

.img-post {
  display: block;
  margin: 10px 0;
  width: 100%;
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media  (min-width: 1200px){
  .container {
    max-width: 1150px;
}
}

// Tabs

.MuiTabs-flexContainer{
  button{
    outline: none;    
  }
}
.MuiTab-textColorPrimary.Mui-selected {
  border-bottom: 3px solid #004e93;
  font-weight: bold;
  color: #004e93 !important;
}

.PrivateTabIndicator-root-1, .PrivateTabIndicator-root-2, .MuiTabs-indicator{
  display: none;
}

.MuiTab-wrapper {
  text-transform: none;
}

.MuiBox-root{
  padding: 24px 0 !important;
}

// Select

.css-yk16xz-control, .css-1pahdxg-control{
  background: transparent !important;
  font-weight: bold;
  color: #2d2c2c;
  border: 0 !important;
  border-bottom: 1px solid #d3d3d3 !important;
  margin-bottom: 30px;
  outline: none;
  font-size: 14px;
  border-radius: 0 !important;
  box-shadow: none !important;

   &:disabled,
  &[readonly] {
    background: #004e93;
    border-color: #004e93;
  }
} 

.css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{
  color: #004e93 !important;
}
.css-1okebmr-indicatorSeparator{
  display: none;
}

.css-26l3qy-menu{
  background: #e5e5e5 !important;
  color: #004e93 !important;
  &:focus{
    background: #e5e5e5;
  }     
}

.css-g1d714-ValueContainer{
  padding: 0;
}

.css-26l3qy-menu

::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.css-26l3qy-menu
  ::-webkit-scrollbar {
  border-radius: 10px;
  background-color: #eee;
  width: 12px;
}

.css-26l3qy-menu
  ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #004e93;   
}

//modal

.backdrop-transparent{
  background: unset;
}
